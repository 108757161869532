<!doctype html>
<html lang="en">
 
  <head>

    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width, initial-scale=1">
    <meta name="description" content="">
    <meta content="" name="keywords">
    <meta name="author" content="@Aerodrios">
    <meta name="generator" content="Hugo 0.82.0">
    <title>MallaClis</title>

     <!-- Google Fonts -->
    <link href="https://fonts.googleapis.com/css?family=Open+Sans:300,300i,400,400i,600,600i,700,700i|Raleway:300,300i,400,400i,500,500i,600,600i,700,700i|Poppins:300,300i,400,400i,500,500i,600,600i,700,700i" rel="stylesheet">

    <style>
      .bd-placeholder-img {
        font-size: 1.125rem;
        text-anchor: middle;
        -webkit-user-select: none;
        -moz-user-select: none;
        user-select: none;
      }

      @media (min-width: 768px) {
        .bd-placeholder-img-lg {
          font-size: 3.5rem;
        }
      }
    </style>

  </head>
 
 
  <body>

     <!-- ======= Header ======= -->
     <header id="header" class="fixed-top d-flex align-items-center">
      <div class="container">
        <div class="header-container d-flex align-items-center justify-content-between">
          
          <div id="logo1" class="logo" >
            <h1 class="text-light" >
              <a href="index.html" class="aLogo">
                  <span class="spLogo">
                    <b>M</b>allaClis</span>
              </a>
            </h1>
            <!-- Uncomment below if you prefer to use an image logo -->
            <!-- <a href="index.html"><img src="assets/img/logo.png" alt="" class="img-fluid"></a>-->
          </div>
  
          <nav id="navbar" class="navbar">
            <ul>
              <li><a class="nav-link scrollto active" href="#" onclick="closeMenu()">Inicio</a></li>
              <li><a class="nav-link scrollto" href="#about" onclick="closeMenu()">Nosotros</a></li>
              <!-- <li><a class="nav-link scrollto" href="#about" onclick="closeMenu()">Malla Ciclonica</a></li>
              <li><a class="nav-link scrollto" href="#about" onclick="closeMenu()">Cerca Electrica</a></li> -->
              <li><a class="nav-link scrollto" href="#services" onclick="closeMenu()">Productos</a></li>
              <li><a class="nav-link scrollto " href="#portfolio">Proyectos</a></li> 
              <li><a class="nav-link scrollto" href="#team" onclick="closeMenu()">Servicios</a></li>
              <!-- <li class="dropdown"><a href="#"><span>Drop Down</span> <i class="bi bi-chevron-down"></i></a>
                <ul>
                  <li><a href="#">Drop Down 1</a></li>
                  <li class="dropdown"><a href="#"><span>Deep Drop Down</span> <i class="bi bi-chevron-right"></i></a>
                    <ul>
                      <li><a href="#">Deep Drop Down 1</a></li>
                      <li><a href="#">Deep Drop Down 2</a></li>
                      <li><a href="#">Deep Drop Down 3</a></li>
                      <li><a href="#">Deep Drop Down 4</a></li>
                      <li><a href="#">Deep Drop Down 5</a></li>
                    </ul>
                  </li>
                  <li><a href="#">Drop Down 2</a></li>
                  <li><a href="#">Drop Down 3</a></li>
                  <li><a href="#">Drop Down 4</a></li>
                </ul>
              </li> -->
              <li><a class="nav-link scrollto" href="#contact" onclick="closeMenu()">Ubicación</a></li>
              <!-- <li><a class="getstarted scrollto" href="#about">Get Started</a></li> -->
            </ul>
            <i id="iMenu" class="bi mobile-nav-toggle bi-list" onclick="changeIdNav()"></i>
          </nav>
  
        </div><!-- End Header Container -->
      </div>
      </header>

      <!-- ======= Hero Section ======= -->
      <section id="hero" class="">
      
        <!-- <div id="myCarousel" class="d-flex align-items-center carousel slide"> -->
          
          <!-- <div class="carousel-indicators">
            <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
            <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
            <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
          </div>  -->
      
          <div class="carousel-inner">
            
            <div class="carousel-item active">
              
              <img src="assets/img/mallaclis/img22.jpg" alt="banner1" width="500" height="600" onclick="changeText()" >
              
              <!-- <svg class="bd-placeholder-img item1" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg> -->
      
              <div class="container">
                <h1>
                  <span class="spLogo">
                    <b>M</b>allaclis
                  </span>
                  
                </h1>
                
                <div class="carousel-caption text-start dvcarCap">
                  <!-- <h1>
                    <span class="spLogo">
                      <b>M</b>allaclis
                    </span>
                  </h1> -->
                  <h3><em>La seguridad de sus bienes es nuestro objetivo</em></h3>
                  <p class="pSub">
                    <sub>Cada lugar donde nos sentimos seguros es un tesoro
                    <!-- <br> -->
                    <em>- Jan Jensen -</em>
                    </sub>
                  </p>
                  <!-- <p><a class="btn btn-lg btn-primary" href="#">Sign up today</a></p> -->
                </div>
              </div> 
            </div>
            
          </div>
          
          
          <!-- <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
  
          <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button> -->

        <!-- </div> -->
      
      </section> 


      <main id="main">
  
  
      <!-- ======= Nosotros Section ======= -->
      <section id="about" class="about">
          <div class="container contIni">
    
            <div class="row content">
              <div class="col-lg-6" data-aos="fade-right" data-aos-delay="100">
                <h2>Expertos en venta e instalacion de malla ciclonica, cercas electricas y todo tipo de alambrados</h2>
                <h3>¡La seguridad de sus bienes nuestro servicio!</h3>
              </div>
              <div class="col-lg-6 pt-4 pt-lg-0" data-aos="fade-left" data-aos-delay="200">
                <p>
                  Somos una empresa orgullosamente mexicana, trabajando desde 1994 en la venta, distribución e instalación de malla ciclónica, cercas eléctricas y todo tipo de alambrado. 
                </p>
                <ul>
                  <li><i class="ri-check-double-line"></i> Contamos con personal altamente capacitado en proyectos de seguridad perimetral, nos especializamos en mantener su propiedad segura.</li>
                  <li><i class="ri-check-double-line"></i> Nuestro compromiso es brindarle seguridad a lo más importante: su familia y sus bienes.</li>
                  <li><i class="ri-check-double-line"></i> En Mallaclis centramos nuestra atención en brindarles la maxima calidad en los productos y servicios que le ofrecemos, tenga la seguridad que nuestro trabajo lo dejara satisfecho y tranquilo al saber que esta bien protegido.</li>
                  <li><i class="ri-check-double-line"></i> Llamenos, permitanos atenderlo y usted mismo compruebe que somos la mejor opción.</li>
                </ul>
                <p class="fst-italic">
                  <strong>En calidad y precio somos garantía.</strong>
                </p>
              </div>
            </div>
    
          </div>
      </section>
    

      <!-- ======= Productos Section ======= -->
      <section id="services" class="services section-bg">
        <div class="container contIni">

          <div class="row">
            <div class="col-lg-4">
              <div class="section-title" data-aos="fade-right">
                <h2>Productos</h2>

                <ul>
                  <li><i class="ri-check-double-line"></i> En Mallaclis contamos con una amplia gama de productos orientados a complementar su sistema de seguridad perimetral.</li>
                  <li><i class="ri-check-double-line"></i> En cuestiones de seguridad nunca hay excesos, inicie o complemente su sistema de seguridad perimetral con nuestros productos.</li>
                  <li><i class="ri-check-double-line"></i> Nuestro compromiso es brindarle seguridad a lo más importante: su familia y sus bienes.</li>
              </ul>

                <!-- <p>En Mallaclis contamos con una amplia gama de productos orientados a complementar su sistema de seguridad perimetral.</p>
                <p>En cuestiones de seguridad nunca hay excesos, inicie o complemente su sistema de seguridad perimetral con nuestros productos.</p>
                <p>Nuestro compromiso es brindarle seguridad a lo más importante: su familia y sus bienes.</p> -->
              </div>
            </div>
            <div class="col-lg-8">
              <div class="row">
                <div class="col-md-6 d-flex align-items-stretch ">
                  <div class="icon-box dvProducts" data-aos="zoom-in" data-aos-delay="100">
                    <div class="icon"><i class="bx bx-pulse"></i></div>
                    <h4><a href="">Concerpua  </a></h4>
                    <p>Impida que los intrusos salten su seguridad perimetral, permitanos venderle e instalarle concerpua y aumente el nivel de seguridad de su cerco perimetral</p>
                  </div>
                </div>

                <div class="col-md-6 d-flex align-items-stretch mt-4 mt-lg-0">
                  <div class="icon-box dvProducts" data-aos="zoom-in" data-aos-delay="200">
                    <div class="icon"><i class="bx bxl-unsplash"></i></div>
                    <h4><a href="">Cinta Plastica</a></h4>
                    <p>Bloquee la visibilidad hacia su propiedad instalando en su malla ciclónica cinta plástica rompevientos para evitar visibilidad dentro del cercado y bloquear los espacios de la malla para evitar escalar en la misma. Se presentan en diversos colores como:</p>
                    <ul class="ulProducts">
                      <li><i class=""></i>Verde</li>
                      <li><i class=""></i>Rojo</li>
                      <li><i class=""></i>Azul</li>
                      <li><i class=""></i>Blanco</li>
                      <li><i class=""></i>Plateado</li>
                    </ul>
                  </div>
                </div>

                <div class="col-md-6 d-flex align-items-stretch mt-4">
                  <div class="icon-box dvProducts" data-aos="zoom-in" data-aos-delay="300">
                    <div class="icon"><i class="bx bx-door-open"></i></div>
                    <h4><a href="">Puertas</a></h4>
                    <p>Complemente su cerca instalando una puerta o porton, Estas son hechas conforme a sus necesidades requeridas con materiales de primera calidad; se realizan puertas y portones batientes de 1 a 2 hojas y corredizos de 1 o 2 hojas.</p>
                  </div>
                </div>

                <div class="col-md-6 d-flex align-items-stretch mt-4">
                  <div class="icon-box dvProducts" data-aos="zoom-in" data-aos-delay="400">
                    <div class="icon"><i class="bx bx-pulse"></i></div>
                    <h4><a href="">Alambre de puas</a></h4>
                    <p>Le instalamos su cerco de puas o bien complementamos su cerco de malla perimetral con la ayuda de alambre de puas. Los complementos de puas son economicos y muy funcionales.</p>
                  </div>
                </div>

                <div class="col-md-6 d-flex align-items-stretch mt-4">
                  <div class="icon-box dvProducts" data-aos="zoom-in" data-aos-delay="400">
                    <div class="icon"><i class="bx bxs-wallet-alt"></i></div>
                    <h4><a href="">Tubería</a></h4>
                    <p>Contamos con una amplia variedad de postes y tubos que nos permiten instalarle mallas y cercas sólidas y seguras.</p>
                  </div>
                </div>

              </div>
            </div>
          </div>

        </div>
      </section>


      <!-- ======= Proyectos Section ======= -->
       <section id="portfolio" class="portfolio">
        <div class="container contIni">

          <div class="section-title" data-aos="fade-left">
            <h2>Proyectos</h2>
            <p>Expertos en venta e instalación de malla ciclónica, cercas eléctricas y todo tipo de alambrados.</p>
          </div>

          <!-- <div class="row" data-aos="fade-up" data-aos-delay="100">
            <div class="col-lg-12 d-flex justify-content-center">
              <ul id="portfolio-flters">
                <li data-filter="*" class="filter-active">All</li>
                <li data-filter=".filter-app">App</li>
                <li data-filter=".filter-card">Card</li>
                <li data-filter=".filter-web">Web</li>
              </ul>
            </div>
          </div> -->

          <!-- <div class="row portfolio-container" data-aos="fade-up" data-aos-delay="200">

            <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img src="assets/img/mallaclis/img4.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Proyecto Malla Perimetral</h4>
                  <p>App</p>
                  <div class="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="App 1"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a> 
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img src="assets/img/mallaclis/img1.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Proyecto malla perimetral con porton abatible</h4>
                  <p>App</p>
                  <div class="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="App 1"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a> 
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img src="assets/img/mallaclis/img2.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Proyecto cerco eléctrico</h4>
                  <p>App</p>
                  <div class="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="App 1"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a> 
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img src="assets/img/mallaclis/img3.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Proyecto malla perimetral con porton abatible</h4>
                  <p>App</p>
                  <div class="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="App 1"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a> 
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img src="assets/img/mallaclis/img5.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Proyecto jaula con puerta de acceso</h4>
                  <p>App</p>
                  <div class="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="App 1"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a> 
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img src="assets/img/mallaclis/img6.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Proyecto 6 </h4>
                  <p>App</p>
                  <div class="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="App 1"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a> 
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img src="assets/img/mallaclis/img7.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Proyecto 7</h4>
                  <p>App</p>
                  <div class="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="App 1"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a> 
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img src="assets/img/mallaclis/img8.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Proyecto 8</h4>
                  <p>App</p>
                  <div class="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="App 1"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a> 
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img src="assets/img/mallaclis/img9.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Proyecto 9</h4>
                  <p>App</p>
                  <div class="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="App 1"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a> 
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img src="assets/img/mallaclis/img10.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Proyecto 10</h4>
                  <p>App</p>
                  <div class="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-1.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="App 1"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a> 
                  </div>
                </div>
              </div>
            </div> -->

            <!-- <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src="assets/img/portfolio/portfolio-2.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Web 3</h4>
                  <p>Web</p>
                  <div class="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-2.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Web 3"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img src="assets/img/portfolio/portfolio-3.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>App 2</h4>
                  <p>App</p>
                  <div class="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-3.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="App 2"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-card">
              <div class="portfolio-wrap">
                <img src="assets/img/portfolio/portfolio-4.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Card 2</h4>
                  <p>Card</p>
                  <div class="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-4.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Card 2"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src="assets/img/portfolio/portfolio-5.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Web 2</h4>
                  <p>Web</p>
                  <div class="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-5.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Web 2"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-app">
              <div class="portfolio-wrap">
                <img src="assets/img/portfolio/portfolio-6.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>App 3</h4>
                  <p>App</p>
                  <div class="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-6.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="App 3"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-card">
              <div class="portfolio-wrap">
                <img src="assets/img/portfolio/portfolio-7.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Card 1</h4>
                  <p>Card</p>
                  <div class="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-7.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Card 1"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-card">
              <div class="portfolio-wrap">
                <img src="assets/img/portfolio/portfolio-8.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Card 3</h4>
                  <p>Card</p>
                  <div class="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-8.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Card 3"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4 col-md-6 portfolio-item filter-web">
              <div class="portfolio-wrap">
                <img src="assets/img/portfolio/portfolio-9.jpg" class="img-fluid" alt="">
                <div class="portfolio-info">
                  <h4>Web 3</h4>
                  <p>Web</p>
                  <div class="portfolio-links">
                    <a href="assets/img/portfolio/portfolio-9.jpg" data-gallery="portfolioGallery" class="portfolio-lightbox" title="Web 3"><i class="bx bx-plus"></i></a>
                    <a href="portfolio-details.html" title="More Details"><i class="bx bx-link"></i></a>
                  </div>
                </div>
              </div>
            </div> -->

          <!-- </div> -->


          <div id="myCarousel" class="d-flex align-items-center carousel slide">
          
            <!-- <div class="carousel-indicators">
              <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="0" class="active" aria-current="true" aria-label="Slide 1"></button>
              <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="1" aria-label="Slide 2"></button>
              <button type="button" data-bs-target="#myCarousel" data-bs-slide-to="2" aria-label="Slide 3"></button>
            </div>  -->
        
            <div class="carousel-inner">
              
              <div class="carousel-item active">
                
                <img src="assets/img/mallaclis/img1.jpg" alt="banner1" width="500" height="600" onclick="changeText()" >
                
                <!-- <svg class="bd-placeholder-img item1" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg> -->
        
                <div class="container">
                  <div class="carousel-caption text-start">
                    <!-- <h1>Example Img 1</h1>
                    <p>Some representative placeholder content for the first slide of the carousel.</p> 
                    <p><a class="btn btn-lg btn-primary" href="#">Sign up today</a></p> -->
                  </div>
                </div> 
              </div>
              
              <div class="carousel-item">
  
                <img src="assets/img/mallaclis/img2.jpg" alt="banner1" width="500" height="600" >
  
                <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
        
                <div class="container">
                  <div class="carousel-caption">
                    <!-- <h1>Example Img 2</h1>
                    <p>Some representative placeholder content for the second slide of the carousel.</p> 
                    <p><a class="btn btn-lg btn-primary" href="#">Learn more</a></p> -->
                  </div>
                </div>
              </div> 

              <div class="carousel-item">
  
                <img src="assets/img/mallaclis/img3.jpg" alt="banner1" width="500" height="600" >
  
                <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
        
                <div class="container">
                  <div class="carousel-caption">
                    <!-- <h1>Example Img 3</h1>
                    <p>Some representative placeholder content for the second slide of the carousel.</p> 
                    <p><a class="btn btn-lg btn-primary" href="#">Learn more</a></p> -->
                  </div>
                </div>
              </div> 

              <div class="carousel-item">
  
                <img src="assets/img/mallaclis/img4.jpg" alt="banner1" width="500" height="600" >
  
                <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
        
                <div class="container">
                  <div class="carousel-caption">
                    <!--<h1>Example Img 4</h1>
                    <p>Some representative placeholder content for the second slide of the carousel.</p> 
                    <p><a class="btn btn-lg btn-primary" href="#">Learn more</a></p> -->
                  </div>
                </div>
              </div> 
        
              <div class="carousel-item">
  
                <img src="assets/img/mallaclis/img5.jpg" alt="banner1" width="500" height="600" >
  
                <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
        
                <div class="container">
                  <div class="carousel-caption">
                    <!-- <h1>Example Img 5</h1>
                    <p>Some representative placeholder content for the second slide of the carousel.</p> 
                    <p><a class="btn btn-lg btn-primary" href="#">Learn more</a></p>-->
                  </div>
                </div>
              </div> 
           
             
              <div class="carousel-item">
  
                <img src="assets/img/mallaclis/img6.jpg" alt="banner1" width="500" height="600" >
  
                <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
        
                <div class="container">
                  <div class="carousel-caption">
                    <!-- <h1>Example Img 6</h1>
                    <p>Some representative placeholder content for the second slide of the carousel.</p> 
                    <p><a class="btn btn-lg btn-primary" href="#">Learn more</a></p>-->
                  </div>
                </div>
              </div> 

              <div class="carousel-item">
  
                <img src="assets/img/mallaclis/img7.jpg" alt="banner1" width="500" height="600" >
  
                <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
        
                <div class="container">
                  <div class="carousel-caption">
                    <!-- <h1>Example Img 7</h1>
                    <p>Some representative placeholder content for the second slide of the carousel.</p> 
                    <p><a class="btn btn-lg btn-primary" href="#">Learn more</a></p>-->
                  </div>
                </div>
              </div> 

              <div class="carousel-item">
  
                <img src="assets/img/mallaclis/img8.jpg" alt="banner1" width="500" height="600" >
  
                <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
        
                <div class="container">
                  <div class="carousel-caption">
                    <!-- <h1>Example Img 8</h1>
                    <p>Some representative placeholder content for the second slide of the carousel.</p> 
                    <p><a class="btn btn-lg btn-primary" href="#">Learn more</a></p>-->
                  </div>
                </div>
              </div> 

              <div class="carousel-item">
  
                <img src="assets/img/mallaclis/img9.jpg" alt="banner1" width="500" height="600" >
  
                <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
        
                <div class="container">
                  <div class="carousel-caption">
                    <!-- <h1>Example Img 9</h1>
                    <p>Some representative placeholder content for the second slide of the carousel.</p> 
                    <p><a class="btn btn-lg btn-primary" href="#">Learn more</a></p> -->
                  </div>
                </div>
              </div> 

              <div class="carousel-item">
  
                <img src="assets/img/mallaclis/img10.jpg" alt="banner1" width="500" height="600" >
  
                <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
        
                <div class="container">
                  <div class="carousel-caption">
                    <!-- <h1>Example Img 10</h1>
                    <p>Some representative placeholder content for the second slide of the carousel.</p> 
                    <p><a class="btn btn-lg btn-primary" href="#">Learn more</a></p> -->
                  </div>
                </div>
              </div> 

              <div class="carousel-item">
  
                <img src="assets/img/mallaclis/img11.jpg" alt="banner1" width="500" height="600" >
  
                <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
        
                <div class="container">
                  <div class="carousel-caption">
                    <!-- <h1>Example Img 11</h1>
                    <p>Some representative placeholder content for the second slide of the carousel.</p> 
                    <p><a class="btn btn-lg btn-primary" href="#">Learn more</a></p> -->
                  </div>
                </div>
              </div> 

              <div class="carousel-item">
  
                <img src="assets/img/mallaclis/img12.jpg" alt="banner1" width="500" height="600" >
  
                <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
        
                <div class="container">
                  <div class="carousel-caption">
                    <!-- <h1>Example Img 12</h1>
                    <p>Some representative placeholder content for the second slide of the carousel.</p> 
                    <p><a class="btn btn-lg btn-primary" href="#">Learn more</a></p> -->
                  </div>
                </div>
              </div> 

              <div class="carousel-item">
  
                <img src="assets/img/mallaclis/img13.jpg" alt="banner1" width="500" height="600" >
  
                <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
        
                <div class="container">
                  <div class="carousel-caption">
                    <!-- <h1>Example Img 13</h1>
                    <p>Some representative placeholder content for the second slide of the carousel.</p> 
                    <p><a class="btn btn-lg btn-primary" href="#">Learn more</a></p> -->
                  </div>
                </div>
              </div> 

              <div class="carousel-item">
  
                <img src="assets/img/mallaclis/img14.jpg" alt="banner1" width="500" height="600" >
  
                <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
        
                <div class="container">
                  <div class="carousel-caption">
                    <!-- <h1>Example Img 14</h1>
                    <p>Some representative placeholder content for the second slide of the carousel.</p> 
                    <p><a class="btn btn-lg btn-primary" href="#">Learn more</a></p> -->
                  </div>
                </div>
              </div> 

              <div class="carousel-item">
  
                <img src="assets/img/mallaclis/img15.jpg" alt="banner1" width="500" height="600" >
  
                <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
        
                <div class="container">
                  <div class="carousel-caption">
                    <!-- <h1>Example Img 15</h1>
                    <p>Some representative placeholder content for the second slide of the carousel.</p> 
                    <p><a class="btn btn-lg btn-primary" href="#">Learn more</a></p> -->
                  </div>
                </div>
              </div> 

              <div class="carousel-item">
  
                <img src="assets/img/mallaclis/img16.jpg" alt="banner1" width="500" height="600" >
  
                <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
        
                <div class="container">
                  <div class="carousel-caption">
                    <!-- <h1>Example Img 16</h1>
                    <p>Some representative placeholder content for the second slide of the carousel.</p> 
                    <p><a class="btn btn-lg btn-primary" href="#">Learn more</a></p> -->
                  </div>
                </div>
              </div> 

              <div class="carousel-item">
  
                <img src="assets/img/mallaclis/img17.jpg" alt="banner1" width="500" height="600" >
  
                <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
        
                <div class="container">
                  <div class="carousel-caption">
                    <!-- <h1>Example Img 17</h1>
                    <p>Some representative placeholder content for the second slide of the carousel.</p> 
                    <p><a class="btn btn-lg btn-primary" href="#">Learn more</a></p> -->
                  </div>
                </div>
              </div> 

              <div class="carousel-item">
  
                <img src="assets/img/mallaclis/img18.jpg" alt="banner1" width="500" height="600" >
  
                <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
        
                <div class="container">
                  <div class="carousel-caption">
                    <!-- <h1>Example Img 18</h1>
                    <p>Some representative placeholder content for the second slide of the carousel.</p> 
                    <p><a class="btn btn-lg btn-primary" href="#">Learn more</a></p> -->
                  </div>
                </div>
              </div> 

              <div class="carousel-item">
  
                <img src="assets/img/mallaclis/img19.jpg" alt="banner1" width="500" height="600" >
  
                <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
        
                <div class="container">
                  <div class="carousel-caption">
                    <!-- <h1>Example Img 19</h1>
                    <p>Some representative placeholder content for the second slide of the carousel.</p> 
                    <p><a class="btn btn-lg btn-primary" href="#">Learn more</a></p> -->
                  </div>
                </div>
              </div> 

              <div class="carousel-item">
  
                <img src="assets/img/mallaclis/img20.jpg" alt="banner1" width="500" height="600" >
  
                <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
        
                <div class="container">
                  <div class="carousel-caption">
                    <!-- <h1>Example Img 20</h1>
                    <p>Some representative placeholder content for the second slide of the carousel.</p> 
                    <p><a class="btn btn-lg btn-primary" href="#">Learn more</a></p> -->
                  </div>
                </div>
              </div> 

              <div class="carousel-item">
  
                <img src="assets/img/mallaclis/img21.jpg" alt="banner1" width="500" height="600" >
  
                <svg class="bd-placeholder-img" width="100%" height="100%" xmlns="http://www.w3.org/2000/svg" aria-hidden="true" preserveAspectRatio="xMidYMid slice" focusable="false"><rect width="100%" height="100%" fill="#777"/></svg>
        
                <div class="container">
                  <div class="carousel-caption">
                    <!-- <h1>Example Img 21</h1>
                    <p>Some representative placeholder content for the second slide of the carousel.</p> 
                    <p><a class="btn btn-lg btn-primary" href="#">Learn more</a></p> -->
                  </div>
                </div>
              </div>  

            </div>
            
            
            <button class="carousel-control-prev" type="button" data-bs-target="#myCarousel" data-bs-slide="prev">
              <span class="carousel-control-prev-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Previous</span>
            </button>
    
            <button class="carousel-control-next" type="button" data-bs-target="#myCarousel" data-bs-slide="next">
              <span class="carousel-control-next-icon" aria-hidden="true"></span>
              <span class="visually-hidden">Next</span>
            </button>
  
          </div>




        </div>
      </section> 


      <!-- ======= Team Section ======= -->
      <section id="team" class="team">
        <div class="container contIni">

          <div class="row">
            <div class="col-lg-4">
              <div class="section-title" data-aos="fade-right">
                <h2>Servicios</h2>
                <p>¡En Mallaclis somos expertos en cuidar de sus bienes mediante la instalacion de seguridad perimetral.!</p>
                <p>Vendemos e instalamos cercas de malla, cercas electrificadas, puertas y portones asi como productos complemento tal como concertina, alambre de puas y cinta plastificada.</p>
                <p>Nuestro compromiso es brindarle seguridad a lo más importante: su familia y sus bienes.</p>
              </div>
            </div>
            <div class="col-lg-8">
              <div class="row">

                <div class="col-lg-6">
                  <div class="member" data-aos="zoom-in" data-aos-delay="100">
                    <div class="pic"><img src="assets/img/mallaclis/img8.jpg" class="img-fluid" alt=""></div>
                    <div class="member-info">
                      <h4>Instalación</h4>
                      <span>Mallas - Cercas</span>
                      <p>Protección y seguridad para empresas</p>
                      <!-- <div class="social">
                        <a href=""><i class="ri-twitter-fill"></i></a>
                        <a href=""><i class="ri-facebook-fill"></i></a>
                        <a href=""><i class="ri-instagram-fill"></i></a>
                        <a href=""> <i class="ri-linkedin-box-fill"></i> </a>
                      </div> -->
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 mt-4 mt-lg-0">
                  <div class="member" data-aos="zoom-in" data-aos-delay="200">
                    <div class="pic"><img src="assets/img/mallaclis/img7.jpg" class="img-fluid" alt=""></div>
                    <div class="member-info">
                      <h4>Adaptación</h4>
                      <span>Mallas - Cercas</span>
                      <p>Adaptación para negocios, casas y terrenos por medio de distintos tipos de cerca</p>
                      <!-- <div class="social">
                        <a href=""><i class="ri-twitter-fill"></i></a>
                        <a href=""><i class="ri-facebook-fill"></i></a>
                        <a href=""><i class="ri-instagram-fill"></i></a>
                        <a href=""> <i class="ri-linkedin-box-fill"></i> </a>
                      </div> -->
                    </div>
                  </div>
                </div>

                <!-- <div class="col-lg-6 mt-4">
                  <div class="member" data-aos="zoom-in" data-aos-delay="300">
                    <div class="pic"><img src="assets/img/team/team-3.jpg" class="img-fluid" alt=""></div>
                    <div class="member-info">
                      <h4>William Anderson</h4>
                      <span>Instructor Zumba</span>
                      <p>Quisquam facilis cum velit laborum corrupti fuga rerum quia</p>
                      <div class="social">
                        <a href=""><i class="ri-twitter-fill"></i></a>
                        <a href=""><i class="ri-facebook-fill"></i></a>
                        <a href=""><i class="ri-instagram-fill"></i></a>
                        <a href=""> <i class="ri-linkedin-box-fill"></i> </a>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-lg-6 mt-4">
                  <div class="member" data-aos="zoom-in" data-aos-delay="400">
                    <div class="pic"><img src="assets/img/team/team-4.jpg" class="img-fluid" alt=""></div>
                    <div class="member-info">
                      <h4>Amanda Jepson</h4>
                      <span>Instructor de Pesas</span>
                      <p>Dolorum tempora officiis odit laborum officiis et et accusamus</p>
                      <div class="social">
                        <a href=""><i class="ri-twitter-fill"></i></a>
                        <a href=""><i class="ri-facebook-fill"></i></a>
                        <a href=""><i class="ri-instagram-fill"></i></a>
                        <a href=""> <i class="ri-linkedin-box-fill"></i> </a>
                      </div>
                    </div>
                  </div>
                </div> -->

              </div>

            </div>
          </div>

        </div>
      </section>


      <!-- ======= Ubicacion Section ======= -->
      <section id="contact" class="contact">
        <div class="container contIni">
          <div class="row">
            <div class="col-lg-4" data-aos="fade-right">
              <div class="section-title">
                <h2>Atencion:</h2>
                <p>Familia Garcia</p>
              </div>
            </div>

            <div class="col-lg-8" data-aos="fade-up" data-aos-delay="100">
              
              <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [mapTypeId]="mapTypeId"  style="border:0; width: 100%; height: 300px;">
                <agm-marker [latitude]="lat" [longitude]="lng" ></agm-marker>
              </agm-map>

              <!-- <iframe style="border:0; width: 100%; height: 270px;" src="" frameborder="0" allowfullscreen></iframe> -->

              <div class="info mt-4">
                <i class="bi bi-geo-alt"></i>
                <h4>Encuentranos:</h4>
                <p>Henequen Edif 72 s/n, Mexico City, México D.F.</p>
              </div>
              <div class="row">
                <div class="col-lg-6 mt-4">
                  <div class="info">
                    <i class="bi bi-envelope"></i>
                    <h4>Email:</h4>
                    <p>mallaclis@hotmail.com</p>
                  </div>
                </div>
                <div class="col-lg-6">
                  <div class="info w-100 mt-4">
                    <i class="bi bi-phone"></i>
                    <h4>Telefonos:</h4>
                    <p>(55) 56-57-46-32</p>
                    <p>(55) 16-93-52-82</p>
                    <!--<p>(55) 56-54-58-92</p> -->
                  </div>
                </div>
              </div>

              <!-- <form action="forms/contact.php" method="post" role="form" class="php-email-form mt-4">
                <div class="row">
                  <div class="col-md-6 form-group">
                    <input type="text" name="name" class="form-control" id="name" placeholder="Your Name" required>
                  </div>
                  <div class="col-md-6 form-group mt-3 mt-md-0">
                    <input type="email" class="form-control" name="email" id="email" placeholder="Your Email" required>
                  </div>
                </div>
                <div class="form-group mt-3">
                  <input type="text" class="form-control" name="subject" id="subject" placeholder="Subject" required>
                </div>
                <div class="form-group mt-3">
                  <textarea class="form-control" name="message" rows="5" placeholder="Message" required></textarea>
                </div>
                <div class="my-3">
                  <div class="loading">Loading</div>
                  <div class="error-message"></div>
                  <div class="sent-message">Your message has been sent. Thank you!</div>
                </div>
                <div class="text-center"><button type="submit">Send Message</button></div>
              </form> -->


            </div>
          </div>

        </div>
      </section>

      </main>
  
      <!-- ======= Footer ======= -->
      <footer id="footer">
    
        <!-- <div class="footer-top">
          <div class="container">
            <div class="row">
    
              <div class="col-lg-2 col-md-6 footer-links">
                <h4>Links</h4>
                <ul>
                  <li><a class="nav-link scrollto active" href="#myCarousel">Home</a></li>
                  <li><a class="nav-link scrollto" href="#about">Nosotros</a></li>
                  <li><a class="nav-link scrollto" href="#services">Clases</a></li>
                  <li><a class="nav-link scrollto" href="#team">Instructores</a></li>
                  <li><a class="nav-link scrollto" href="#contact">Ubicación</a></li>
                </ul>
              </div>
    
              <div class="col-lg-3 col-md-6 footer-links">
                <h4>@Aerodrios Services</h4>
                <ul>
                  <li><i class="bx bx-chevron-right"></i> <a href="#">Twitter</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a href="#">Facebook</a></li>
                  <li><i class="bx bx-chevron-right"></i> <a href="#">Web Page</a></li>
                </ul>
              </div>
    
              <div class="col-lg-4 col-md-6 footer-newsletter"> 
                <h4>Join Our Newsletter</h4>
                <p>Tamen quem nulla quae legam multos aute sint culpa legam noster magna</p>
                <form action="" method="post">
                  <input type="email" name="email"><input type="submit" value="Subscribe">
                </form>
              </div> 
    
            </div>
          </div>
        </div> -->
    
        <div class="container d-md-flex py-4">
    
          <div class="me-md-auto text-center text-md-start">
            <div class="copyright">
              &copy; Copyright <strong><span>@aerodrios</span></strong>
            </div>
            <div class="credits">
              Designed by <a href="https://bootstrapmade.com/">@aerodrios</a>
            </div>
          </div>
          <div class="social-links text-center text-md-right pt-3 pt-md-0">
            <a href="#" class="twitter"><i class="bx bxl-twitter"></i></a>
            <a href="https://acortar.link/GeYFvG" class="facebook"><i class="bx bxl-facebook"></i></a>
            <a href="#" class="instagram"><i class="bx bxl-instagram"></i></a>
            <!-- <a href="#" class="google-plus"><i class="bx bxl-skype"></i></a>
            <a href="#" class="linkedin"><i class="bx bxl-linkedin"></i></a> -->
          </div>
        </div>
      </footer><!-- End Footer -->
  
      <a href="#" class="back-to-top d-flex align-items-center justify-content-center"><i class="bi bi-arrow-up-short"></i></a>
  
      <script src="../../../assets/js/main2.js"></script> 

      
      
   
  </body>
  

</html>
